



































































import Ueditor from "@/components/Ueditor/index.vue";
import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api";
import {
  AttachmentHostType,
  FundProjectManualCreateDonateOrderInput,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import AbSelect from "@/components/AbSelect/index.vue";

@Component({
  components: {
    AbSelect,
    Ueditor,
  },
})
export default class CreateDonateOrder extends Vue {
  @Ref() readonly dataForm!: ElForm;
  form: FundProjectManualCreateDonateOrderInput = {};
  userList: any[] = [];
  btnDisabled = false;

  get hostTypeFile() {
    return AttachmentHostType.FundApply;
  }

  created() {
    this.form.fundProjectId = Number(this.$route.params.id);
    this.fetchUserList();
  }

  async fetchUserList() {
    await api.fundUser.getFundUserList({}).then((res: any) => {
      this.userList = res ?? [];
      this.userList.forEach((item: any) => {
        item.value = Number(item.value);
      });
    });
  }

  async save() {
    this.btnDisabled = true;
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        await api.fundProject
          .manualCreateDonateOrder({
            body: this.form,
          })
          .then(() => {
            this.$router.back();
            this.$message.success("操作成功");
          });
      } else {
        this.$message.error("请检查表单！");
        this.btnDisabled = false;
      }
    });
  }

  cancel() {
    this.$router.back();
    // (this.$refs.dataForm as any).resetFields();
    // this.$emit("input", false);
  }

  roleRule = {
    donationAmount: [
      {
        required: true,
        message: "捐助数额必填",
        trigger: "blur",
      },
    ],
    userId: [
      {
        required: true,
        message: "会员ID必填",
        trigger: "blur",
      },
    ],
    mobileTelephone: [
      {
        required: true,
        message: "手机号码必填",
        trigger: "blur",
      },
    ],
    displayName: [
      {
        required: true,
        message: "显示的名称必填",
        trigger: "blur",
      },
    ],
  };
}
